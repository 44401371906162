<template>
  <div>
    <GameRoomList :gameRooms="privateGameList" :isPrivate="true"/>
  </div>
</template>

<script>
import GameRoomList from '../../components/GameRoomList';

export default {
  components:{
    GameRoomList
  },
  data(){
    return{
      privateGameList: []
    }
  },
  computed:{
    gameList(){
      return this.$store.getters.getGameList.filter(item => item.type === "PRIVATE");
    }
  },
  watch:{
    gameList(newValue){
      this.privateGameList = newValue
    }
  },
  mounted(){
    this.privateGameList = this.gameList;
  }
}
</script>

<style>

</style>